/**
 * Created by wilk on 15.05.2017.
 */
import {Component, Input, OnInit} from '@angular/core';
import {ControlContainer, NgForm, UntypedFormGroup, Validators} from '@angular/forms';
import {
  BusinessPropertiesService,
  BusinessUnitIdDto,
  CustomValidators,
  DictionaryBaseService,
  DictionaryDto,
  DictionaryService,
  LoggedUserService,
  PolicyContractService,
  PolicyContractVersionDto,
  PolicyInquiryTypeEnum,
  RouterService,
  UserIdDto,
  UserService,
} from '../../../bonding_shared';
import {AbstractInquiryProduct} from './abstract-inquiry.product';
import {DictionaryPropertyType, EconomicCountryGroup} from '../../../bonding_shared/model/dictionary-ids';
import {NumberUtils} from '../../../bonding_shared/utils/number-utils';
import {VisibilityService} from '../services/visibility.service';

@Component({
  selector: 'inquiry-product-credendo',
  templateUrl: './inquiry-product-credendo.component.pug',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class InquiryProductCredendoComponent extends AbstractInquiryProduct implements OnInit {
  @Input()
  myOptions: DictionaryDto[];

  private countriesById: DictionaryDto[];
  turnoverSum: number;
  turnoverOECDSum: number;
  turnoverNonOECDSum: number;
  underwriters: UserIdDto[] = null;

  readonly policyInquiryType = 'POLICY_INQUIRY';

  constructor(
    dictionaryBaseService: DictionaryBaseService,
    router: RouterService,
    private userService: UserService,
    businessPropertiesService: BusinessPropertiesService,
    private dictionaryService: DictionaryService,
    protected policyContractService: PolicyContractService,
    protected loggedUserService: LoggedUserService,
    protected visibilityService: VisibilityService
  ) {
    super(
      dictionaryBaseService,
      router,
      loggedUserService,
      policyContractService,
      businessPropertiesService,
      visibilityService
    );
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.form.addControl('lostReceivables', new UntypedFormGroup({}));
    this.form.addControl('individualLosses', new UntypedFormGroup({}));
    this.form.addControl('outstandingsBuyers', new UntypedFormGroup({}));

    this.form
      .get('turnoverInsurable')
      .setValidators(
        Validators.compose([
          Validators.required,
          CustomValidators.eqSumOfFields(['domesticInsurable', 'exportInsurable']),
        ])
      );

    this.dictionaryService.getDictionary('Country').subscribe((data) => {
      this.countriesById = [];
      data.forEach((country) => (this.countriesById[country.id] = country));
      this.recalculateOECDToTurnoverSplit();
    });

    this.loadUnderwriters();

    this.formUpdate();
  }

  protected loadSalesUsers() {
    const businessUnit: BusinessUnitIdDto = this.inquiry.businessUnit;
    this.userService.getSalesUsers(businessUnit).subscribe((users) => {
      this.salesUsers = users;
    });
  }

  public recalculateOECDToTurnoverSplit() {
    this.turnoverOECDSum = 0;
    this.turnoverNonOECDSum = 0;
    this.turnoverSum = 0;

    this.inquiry.countries.forEach((value) => {
      if (value.lastYearsTO) {
        this.turnoverSum = this.turnoverSum + value.lastYearsTO;
        if (this.countriesById[value.country.id].properties[DictionaryPropertyType.COUNTRY_OECD] === 'true') {
          this.turnoverOECDSum = this.turnoverOECDSum + value.lastYearsTO;
        } else {
          this.turnoverNonOECDSum = this.turnoverNonOECDSum + value.lastYearsTO;
        }
      }
    });

    this.inquiry.countryGroups.forEach((value) => {
      if (value.lastYearsTO) {
        this.turnoverSum = this.turnoverSum + value.lastYearsTO;
        if (value.countryGroup.id === EconomicCountryGroup.OECD) {
          this.turnoverOECDSum = this.turnoverOECDSum + value.lastYearsTO;
        } else {
          this.turnoverNonOECDSum = this.turnoverNonOECDSum + value.lastYearsTO;
        }
      }
    });
  }

  percentOfTurnover(value: number) {
    if (value && this.turnoverSum) {
      return NumberUtils.roundMoney((value * 100) / this.turnoverSum);
    } else {
      return 0;
    }
  }

  protected loadUnderwriters() {
    if (!this.readonly) {
      this.userService.getPolicySupport().subscribe((res) => {
        this.underwriters = res;
      });
    }
  }

  onSelectPolicy(c: PolicyContractVersionDto) {
    this.inquiry.policyInquiry.policyContract = c.policyContract;
  }

  protected formUpdate() {
    super.formUpdate();

    if (this._inquiry && this.form) {
      this.form.get('turnoverInsurable').setValue(this._inquiry.turnoverInsurable);
      this.form.get('domesticInsurable').setValue(this._inquiry.domesticInsurable);
      this.form.get('exportInsurable').setValue(this._inquiry.exportInsurable);
    }
  }
}
